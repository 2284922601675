<template>
  <div id="NewProductList" style="padding: 1rem 0">
    <!-- NEW PRODUCT SECTION -->
    <section v-if="products">
      <div class="columns is-multiline">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="column is-4-desktop is-6-tablet is-12-mobile"
        >
          <product-card :productItem="product" />
        </div>
      </div>
      <div v-if="products.length > 3">
        <a @click="navigateToCategoryCollection()">
          {{ $t("btn.view_more") }}
        </a>
      </div>
    </section>
    <section v-else class="hero is-large">
      <div class="hero-body">
        <div class="container">
          <h2 class="title">
            {{ $t("label.loading") }}
          </h2>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import ProductApiManager from "@/api/ProductApiManager";
import ProductCard from "@/components/cards/ProductCard.vue";

export default {
  name: "CategoryCollection",
  components: {
    ProductCard
  },
  watch: {
    current_lang: function(val) {
      if (val) {
        this.products = null;
        this.getNewProductList();
      }
    }
  },
  computed: mapState(["current_lang"]),
  data() {
    return {
      products: null
    };
  },
  methods: {
    navigateToCategoryCollection: function() {
      this.$router.push({
        name: "collection"
      });
    },
    getNewProductList: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await ProductApiManager.requestNewestProductList(9);
      loadingComponent.close();
      switch (response.code) {
        case "0":
          this.products = response.data;
          break;

        default:
          break;
      }
    },
    navigateToCategoryDetail: function(category) {
      this.$router.push({
        name: "category-detail",
        params: { category }
      });
    }
  },
  mounted() {
    window.scrollTo(0, 0);
    this.getNewProductList();
  }
};
</script>
