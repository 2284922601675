<template>
  <div
    id="NewsCard"
    class="card hoverable"
    style="border-radius: 10px; height:100%"
    @click="navigateToNewsDetail(newsItem)"
  >
    <div class="card-image">
      <figure class="image is-3by2">
        <img
          class="center-cover-image"
          :src="newsItem.cover_image"
          alt="Placeholder image"
          style="border-top-left-radius: 10px; border-top-right-radius: 10px;"
        />
      </figure>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <p class="title is-5">
            <v-clamp class="has-text-left" autoresize :max-lines="3">
              {{ newsItem.header }}
            </v-clamp>
          </p>
        </div>
      </div>

      <div class="content" style="text-align: left;">
        <v-clamp class="has-text-left" autoresize :max-lines="3">
          {{ newsItem.short_description }}...
          <template v-if="clamped" slot="after" slot-scope="{ clamped }">
            <!-- The code below goes into the header slot -->
            <a @click="navigateToNewsDetail(newsItem)">
              {{ $t("btn.read_more") }}
            </a>
          </template>
        </v-clamp>
      </div>
    </div>
  </div>
</template>

<script>
import VClamp from "vue-clamp";

export default {
  name: "NewsCard",
  components: {
    VClamp
  },
  props: ["newsItem"],
  methods: {
    navigateToNewsDetail: function(news) {
      this.$router.push({
        name: "news-detail",
        params: { id: news._id }
      });
    }
  }
};
</script>

<style scoped>
#NewsCard {
  margin: 0 0.75rem;
}
</style>
