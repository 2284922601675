<template>
  <div id="NewsList" style="padding: 1rem 0">
    <div v-if="news && news.length > 0">
      <!-- Mobile -->
      <b-carousel-list
        class="is-hidden-mobile"
        v-model="index"
        :data="news"
        :items-to-show="3.2"
      >
        <template slot="item" slot-scope="props">
          <news-card :newsItem="props.list" />
        </template>
      </b-carousel-list>
      <!-- Others -->
      <b-carousel-list
        class="is-hidden-tablet"
        v-model="index"
        :data="news"
        :items-to-show="1.2"
      >
        <template slot="item" slot-scope="props">
          <news-card :newsItem="props.list" />
        </template>
      </b-carousel-list>
    </div>
    <div v-else>
      <h1 class="subtitle">No Updated News</h1>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import NewsCard from "@/components/cards/NewsCard.vue";
import NewsApiManager from "@/api/NewsApiManager";

export default {
  name: "NewsList",
  components: {
    NewsCard
  },
  data() {
    return {
      news: [],
      index: 0
    };
  },
  watch: {
    current_lang: function(val) {
      if (val) {
        this.getNewFeed();
      }
    }
  },
  computed: {
    ...mapState(["current_lang"])
  },
  methods: {
    getNewFeed: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApiManager.requestNewsList();
      switch (response.code) {
        case "0":
          this.news = response.data;
          break;

        default:
          break;
      }
      loadingComponent.close();
    }
  },
  mounted() {
    this.getNewFeed();
  }
};
</script>

<style scoped>
.carousel-list.has-shadow {
  box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}
</style>
