<template>
  <div id="HeroBanner">
    <b-carousel v-if="banners" indicator-style="is-boxes">
      <b-carousel-item v-for="(banner, i) in banners" :key="i">
        <figure
          :class="banner.type !== 'cover' ? 'hoverable' : ''"
          @click="navigateToItemDetail(banner)"
        >
          <img class="center-cropped" :src="banner.cover_image" />
        </figure>
      </b-carousel-item>
    </b-carousel>
    <div v-else>
      <section class="hero is-primary is-large">
        <div class="hero-body">
          <div class="container">
            <h1 class="title">
              Blushing Owl Studio official website
            </h1>
            <h2 class="subtitle">
              Limited art products. Our wish is that our toys would bring love
              and joy to you.
            </h2>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import BannerApiManager from "@/api/BannerApiManager";

export default {
  name: "HeroBanner",
  watch: {
    current_lang: function(val) {
      if (val) {
        this.getHeroBanners();
      }
    }
  },
  computed: mapState(["current_lang"]),
  data() {
    return {
      banners: null,
      banner_placeholder: "banners/banner-3.jpg"
    };
  },
  methods: {
    navigateToItemDetail: function(item) {
      if (item.item_id) {
        switch (item.type) {
          case "product":
            this.$router.push({
              name: "product-info",
              params: { id: item.item_id }
            });
            break;
          case "news":
            this.$router.push({
              name: "news-detail",
              params: { id: item.item_id }
            });
            break;
          default:
            break;
        }
      }
    },
    getHeroBanners: async function() {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await BannerApiManager.requestBanners();
      switch (response.code) {
        case "0":
          this.banners = response.data;
          break;

        default:
          break;
      }
      loadingComponent.close();
    }
  },
  mounted() {
    this.getHeroBanners();
  }
};
</script>

<style scoped>
.aspect-ratio {
  width: 100%;
  height: 40vw;
}
.center-cropped {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 40vw;
  width: 100%;
}
</style>
