<template>
  <div id="Home">
    <!-- BANNER CAROUSEL -->
    <hero-banner />

    <!-- NEWS SECTION -->
    <section class="section" style="padding-bottom: 0;">
      <div class="container">
        <h1 class="title">{{ $t("home.section_news.title") }}</h1>
        <news-list />
      </div>
    </section>

    <!-- PRODUCTS SECTION -->
    <section class="section">
      <div class="container">
        <h1 class="title">{{ $t("home.section_product.title") }}</h1>
        <new-product-list />
      </div>
    </section>

    <!-- PROMISING SECTION -->
    <section
      class="section"
      style="background-color: #20a6b7; padding-top: 1.5rem; padding-bottom: 1.5rem;"
    >
      <div class="container">
        <h1 class="title" style="color: white;">
          {{ $t("home.section_benefit.title") }}
        </h1>
        <h2 class="subtitle is-6" style="color: white; padding: 1rem 2rem ">
          {{ $t("home.section_benefit.subtitle") }}
        </h2>
        <section style="padding: 1rem 0 0 0;">
          <div class="columns is-multiline is-mobile">
            <!-- SECURE PAYMENT -->
            <div class="column is-4-desktop is-4-tablet is-4-mobile">
              <div class="block">
                <b-icon
                  icon="shield-check"
                  custom-size="mdi-48px"
                  type="is-warning"
                ></b-icon>
              </div>
              <p class="title is-6 benefit-info" style="color: white;">
                {{ $t("home.section_benefit.title_benefit1") }}
              </p>
            </div>
            <!-- FAST DELIVERY -->
            <div class="column is-4-desktop is-4-tablet is-4-mobile">
              <div class="block">
                <b-icon
                  icon="truck-fast"
                  custom-size="mdi-48px"
                  type="is-warning"
                ></b-icon>
              </div>
              <p class="title is-6 benefit-info" style="color: white;">
                {{ $t("home.section_benefit.title_benefit2") }}
              </p>
            </div>
            <!-- QUALITY PRODUCT-->
            <div class="column is-4-desktop is-4-tablet is-4-mobile">
              <div class="block">
                <b-icon
                  icon="hand-heart"
                  custom-size="mdi-48px"
                  type="is-warning"
                ></b-icon>
              </div>
              <p class="title is-6 benefit-info" style="color: white;">
                {{ $t("home.section_benefit.title_benefit3") }}
              </p>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroBanner from "@/components/HeroBanner.vue";
import NewsList from "@/components/list/NewsList.vue";
import NewProductList from "@/components/list/NewProductList.vue";

export default {
  name: "Home",
  components: {
    HeroBanner,
    NewsList,
    NewProductList
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$analytics.logEvent("home_page_visit");
  }
};
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  opacity: 0.3;
}
.benefit-info {
  font-size: clamp(10px, 2.5vw, 1rem);
}
</style>
