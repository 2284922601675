<template>
  <div id="ProductCard" v-if="productInfo">
    <figure
      class="image hoverable"
      @click="navigateToProductDetail(productInfo)"
    >
      <img class="variant-img" :src="productInfo.main_image" />
    </figure>
    <div
      class="hoverable"
      style="padding: 1rem 0.5rem"
      @click="navigateToProductDetail(productInfo)"
    >
      <p
        v-if="productInfo.name.en"
        class="subtitle"
        style="margin-bottom: 0.5rem"
      >
        {{ productInfo.name[current_lang] }}
      </p>
      <p v-else class="subtitle" style="margin-bottom: 0.5rem">
        {{ productInfo.name }}
      </p>

      <!-- PRICE -->
      <div v-if="isDiscount" style="margin-top: 1rem; margin-bottom: 1rem">
        <p class="subtitle is-6">
          <b-tag type="is-danger" style="text-decoration: none">
            {{ (productInfo.price / productInfo.full_price) | decimal }}% OFF
          </b-tag>
          <span
            style="
              margin-left: 0.25rem;
              text-decoration: line-through;
              color: #b5b5b5;
            "
          >
            {{ productInfo.full_price | thousandseparators }}
            {{ $t("label.currency_unit") }}
          </span>
        </p>
        <p class="title is-4" style="color: #f14668">
          {{ productInfo.price | thousandseparators }}
          {{ $t("label.currency_unit") }}
        </p>
      </div>
      <div v-else style="margin-top: 1rem; margin-bottom: 1rem">
        <p class="title is-4">
          {{ productInfo.price | thousandseparators }}
          {{ $t("label.currency_unit") }}
        </p>
      </div>

      <!-- TAG -->
      <!-- 
      <p v-if="productInfo.instock > 0 && productInfo.instock <= 10">
        <b-tag type="is-warning">
          {{ $t("label.instock") }}: {{ productInfo.instock }}
          {{ $t("label.items") }}
        </b-tag>
      </p>
      <p v-else-if="productInfo.instock <= 0">
        <b-tag type="is-light">
          {{ $t("label.outstock") }}
        </b-tag>
      </p>
      <p v-else></p>
      -->
    </div>
    <div v-if="productInfo.preorder">
      <b-button
        :type="productInfo.instock <= 0 ? 'is-dark' : 'is-primary'"
        outlined
        :disabled="productInfo.instock <= 0"
        @click="addToCartButtonPressed(productInfo)"
      >
        {{ $t("btn.pre_order") }}
      </b-button>
    </div>
    <div v-else>
      <b-button
        :type="productInfo.instock <= 0 ? 'is-dark' : 'is-primary'"
        outlined
        :disabled="productInfo.instock <= 0"
        @click="addToCartButtonPressed(productInfo)"
      >
        {{ $t("btn.add_to_cart") }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import Helper from "@/utils/helper";
import CartApiManager from "@/api/CartApiManager";

export default {
  name: "ProductCard",
  props: ["productItem"],
  data() {
    return {
      productInfo: this.productItem
    };
  },
  computed: {
    ...mapState(["cart", "current_lang"]),
    isDiscount: function() {
      if (
        this.productInfo.full_price &&
        this.productInfo.full_price !== this.productInfo.price
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  filters: {
    thousandseparators: function(value) {
      return Helper.toThousandSeparatorsFormatter(value);
    },
    decimal: function(value) {
      let discount = 1 - value;
      return Math.round((discount + Number.EPSILON) * 100);
    }
  },
  methods: {
    ...mapMutations({
      setCart: "SET_CART"
    }),
    navigateToProductDetail: function(product) {
      const product_id = product._id;
      const category = product.cat_id;
      this.$router.push({
        name: "product-detail",
        params: { category, id: product_id }
      });
    },
    addToCartButtonPressed: function(product) {
      if (1 <= this.productInfo.instock && this.productInfo.instock !== 0) {
        let cartItem = {
          product_id: product._id
        };
        let matchedItem = null;
        if (this.cart.products) {
          matchedItem = this.cart.products.find(
            item => item.product_id === product._id
          );
        }
        if (matchedItem) {
          cartItem.quantity = matchedItem.quantity + 1;
        } else {
          cartItem.quantity = 1;
        }

        // Get Cart ID, If exist update the cart. else create new cart with item
        if (this.cart._id) {
          const cart_id = this.cart._id;
          this.requestAddtoExistingCart(cart_id, cartItem);
        } else {
          this.requestAddtoNewCart(cartItem);
        }
      }
    },
    requestAddtoExistingCart: async function(cart_id, cartItem) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApiManager.requestUpdateItemToExistingCart(
        cart_id,
        cartItem
      );
      switch (response.code) {
        case "0":
          this.presentAddtoCartToast();
          this.setCart(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    requestAddtoNewCart: async function(cartItem) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await CartApiManager.requestCreateNewCart(cartItem);
      switch (response.code) {
        case "0":
          this.presentAddtoCartToast();
          this.setCart(response.data);
          break;

        default:
          break;
      }
      loadingComponent.close();
    },
    presentAddtoCartToast() {
      this.$buefy.snackbar.open("Product was added to cart!");
    }
  }
};
</script>

<style>
#ProductCard {
  margin: 0.25rem 1rem !important;
}
.variant-img {
  border-radius: 0px;
  object-position: center;
  object-fit: cover;
}
</style>
