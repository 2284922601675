import MainConnection from "./MainConnection";

export default {
  requestNewsList: async function() {
    try {
      const requestURL = "/news";
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestNewsDetail: async function(news_id) {
    try {
      const requestURL = "/news/" + news_id;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
