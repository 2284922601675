import MainConnection from "./MainConnection";

export default {
  requestUpdateItemToExistingCart: async function(cart_id, params) {
    try {
      const requestURL = "/carts/" + cart_id;
      const response = await MainConnection.request().post(requestURL, params);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestCreateNewCart: async function(params) {
    try {
      const requestURL = "/carts";
      const response = await MainConnection.request().post(requestURL, params);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestGetCart: async function(cart_id) {
    try {
      const requestURL = "/carts/" + cart_id;
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  },
  requestValidateCart: async function(cart_id) {
    try {
      const requestURL = "/carts/" + cart_id + "/validate";
      const response = await MainConnection.request().get(requestURL);
      return response.data;
    } catch (error) {
      MainConnection.errorMessage(error);
    }
  }
};
